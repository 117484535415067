<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import loadSections from "@/mixins/loadSections";
// import firebaseBindOrder from "@/mixins/firebase/bindOrder";
import firebaseDeliveryBindOrder from "@/mixins/firebase/bindDeliveryOrder";

export default {
  name: "MenuOrderDetailsPaymentThankYou",

  metaInfo: { title: "Detalle del pago" },

  extends: View,

  mixins: [
    // firebaseBindOrder,
    firebaseDeliveryBindOrder,
    loadSections([
      "public-menu-order-details-payment-thank-you",
      "layout-footer-menu-order-details-payment-thank-you",
    ]),
  ],
};
</script>
